.pricing {

	.tiles-item-inner {
		background: get-color(dark, 2);
	}
}

.pricing-item-header {
	@include divider(after);

	&::after {
		background: get-color(dark, 3);
		max-width: 88px;
	}
}

.pricing-item-price-currency {
	color: color(base);
}

ul.pricing-item-features-list {
	@include divider(after);

	&::after {
		background: get-color(dark, 3);
	}

	li {
		justify-content: space-between;
		margin-bottom: 0;
		padding: 14px 0;
		@include divider(before);

		&::before {
			background: get-color(dark, 3);
		}

		&::after {
			content: '';
			display: block;
			width: 16px;
			height: 12px;
			margin-left: 12px;
			background-image: inline-svg('<svg width="16" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M1 5h14v2H1z" fill="' + get-color(dark, 3) + '" fill-rule="nonzero"/></svg>');
			background-repeat: no-repeat;
		}

		&.is-checked {

			&::after {
				background-image: inline-svg('<svg width="16" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M5.6 6.4L1.6 4 0 5.6 5.6 12 16 1.6 14.4 0z" fill="' + get-color(alert, success) + '" fill-rule="nonzero"/></svg>');
			}
		}
	}
}